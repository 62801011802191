/* eslint-disable jsx-a11y/anchor-has-content */
import { useState } from "react";
import "./App.css";
import MainMint from "./MainMint";
import NavBar from "./NavBar";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function App() {
  const [accounts, setAccounts] = useState([]);

  function toggleMenu() {
    const menu = document.getElementById("mobileNavContainer");
    menu.classList.toggle("open-menu");
    console.log("pressed");
  }

  return (
    <div className="overlay">
      <div>
        {/* <div id="mobileNavContainer" className="mobile-nav">
        <div className="mobile-nav-close-button">
          <img src="/icons/close.svg" alt="" onClick={toggleMenu} />
        </div>
        <ul>
        
          <li>
            <a href="/#mint" onClick={toggleMenu}>
            Mint
            </a>
          </li>
          <li>
            <a href="/#welcome" onClick={toggleMenu}>
            Welcome
            </a>
          </li>
          <li>
            <a href="/#milestones" onClick={toggleMenu}>
            Roadmap
            </a>
          </li>
          <li>
            <a href="/#faq" onClick={toggleMenu}>
            FAQ
            </a>
          </li>
          <li>
            <div className="social-icons">
            <a href="https://twitter.com/diedbutsurvived" target="_blank" rel="noreferrer">
              <img className="nav-social" src="/icons/twitter.svg" alt="" />
            </a>
            <a href="https://discord.gg/qM32teyM8p" target="_blank" rel="noreferrer">
              <img className="nav-social" src="/icons/discord.svg" alt="" />
            </a>
            <a href="https://instagram.com/idiedbutisurvived/" target="_blank" rel="noreferrer">
              <img className="nav-social" src="/icons/instagram.svg" alt="" />
            </a>
        <a href="https://opensea.io/collection/i-died-but-i-survived/" target="_blank" rel="noreferrer">
              <img className="nav-social" src="/icons/opensea.svg" alt="" />
            </a>
            
            </div>

            



          </li>
        </ul>
      </div> */}

        {/* <div className="mobile-menu-button" onClick={toggleMenu}>
        <img src="/icons/menu.svg" alt="" />
       </div> */}

        {/* <nav>
        <div className="nav-container">
        <NavBar accounts={accounts} setAccounts={setAccounts} />
          <a className="hide-800" href="/#mint">
          Mint
          </a>
          <a className="hide-800" href="/#welcome">
          Welcome
          </a>
          <a className="hide-800" href="/#milestones">
          Roadmap
          </a>
          <a className="hide-800" href="/#faq">
          FAQ
          </a>
          <div className="social-icons hide-800">
            <a href="https://twitter.com/diedbutsurvived" target="_blank" rel="noreferrer">
              <img className="nav-social" src="/icons/twitter.svg" alt="" />
            </a>
            <a href="https://discord.gg/qM32teyM8p" target="_blank" rel="noreferrer">
              <img className="nav-social" src="/icons/discord.svg" alt="" />
            </a>
            <a href="https://instagram.com/idiedbutisurvived/" target="_blank" rel="noreferrer">
              <img className="nav-social" src="/icons/instagram.svg" alt="" />
            </a>
           
         <a href="https://opensea.io/collection/i-died-but-i-survived/" target="_blank" rel="noreferrer">
              <img className="nav-social" src="/icons/opensea.svg" alt="" />
            </a>
            
          </div>
        </div>
      </nav> */}

        <div className="header"></div>
        

<div class="container-con">
  <div class="row-con">
    <div class="col-md-12 text-center">
      <h3 class="animate-charcter">Site Under Construction</h3>
    </div>
  </div>
</div>

        {/* <div className="charities">
       
        <div className="flexcharities">
          <div className="charity">
           

          
           <ul>
           <a href="https://thegivingblock.com/" target="_blank" rel="noreferrer">https://thegivingblock.com/</a>
           </ul>
            <h2>If you are struggling, there is help out there for you. The following links can take you to resources that can help.</h2>
            
            
            
          </div>
          <div className="charity">
           

          

           <ul>
           <a href="https://afsp.org/" target="_blank" rel="noreferrer">https://afsp.org/</a>
            </ul>

            <h2>If you are struggling, there is help out there for you. The following links can take you to resources that can help.</h2>
            
            
            
          </div>
          <div className="charity">
           


            <ul>
            <a href="https://988lifeline.org/" target="_blank" rel="noreferrer">https://988lifeline.org/</a>
            </ul>

            <h2>If you are struggling, there is help out there for you. The following links can take you to resources that can help.</h2>
            
            
            
          </div>
          <div className="charity">

            <ul>
            <a href="https://www.psychiatry.org/" target="_blank" rel="noreferrer">https://www.psychiatry.org/</a>
            </ul>
            <h2>If you are struggling, there is help out there for you. The following links can take you to resources that can help.</h2>
            
            
            
          </div>
        </div>
      </div> */}

        {/* <section id="mint" className="mint-part py-6 jumptarget">
        <div className="container">



          <div className="comingsoon">
            <h2>Mint is On</h2>
          </div>
            <div className="row align-items-center">
             <div className="col-xl-6 text-center mb-xl-0 mb-3 aos-init aos-animate" data-aos="fade-up">    
           
             <div className="App">
             <div className="Minting">
               <NavBar className="Mintbutton" accounts={accounts} setAccounts={setAccounts} />
               </div>
              <MainMint accounts={accounts} setAccounts={setAccounts} />
             </div>

             </div>

             <div className="col-xl-6 aos-init" data-aos="fade-up" >   
              <div className="numbers-main">
                <h2>MINT NOW</h2>
                <ul className="list-unstyled number-list mb-0">
                  <li><img className="mr-2"
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA2ZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDozREU3ODQ1RUQ0QjJFOTExOUFBNjhCMkZFNUU4RDk1MCIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDpCMDE2RjJDNDFBRDMxMUVDODBGNDk3REM3N0IyMTFBMyIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDpCMDE2RjJDMzFBRDMxMUVDODBGNDk3REM3N0IyMTFBMyIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgQ1M2IChXaW5kb3dzKSI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjBBRjY0NUQzQ0QxQUVDMTE5MkY4OEI2NDc4MDRGOTIxIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjNERTc4NDVFRDRCMkU5MTE5QUE2OEIyRkU1RThEOTUwIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+rPVkrwAAAQFJREFUeNpiZqAM2ADxeiC2pMQQISC+CMT/gfgtuYZwAfEyqCF/gDiCXINygfgX1KA+cg0xB+InUEMOATEnOYaIAfFpqCEPgdiQHEPYgLgDaggIZ5DrJVsg/gY1ZB4Qs6MrYILahg8IAvEVqCFngFgZm6K5QHwLiFOBmAWHQQVQQ34CcRou254ipYd6IObA4prPUDVb8DnbFIgvIwXiBGiCg4F2qPgnIHYiFJDyQLwfybCZSIEJc/FuYmNFFIg3IRlWDcS+QPwVGja+pESxMBBvgBoEMuA5EP8D4rt4IgInEAHijUguA+H55CY+UExtRTIom5Lyhgdq2B0gViCkGCDAAMT4RD4+ZXKNAAAAAElFTkSuQmCC"
                    alt="cheak" /> I Died But I Survived are available for minting. Mint yours and can at least save one life.</li>

                </ul>
                <h2><br />SPECS</h2>
                <ul className="list-unstyled number-list mb-0">
                  <li><img className="mr-2"
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA2ZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDozREU3ODQ1RUQ0QjJFOTExOUFBNjhCMkZFNUU4RDk1MCIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDpCMDE2RjJDNDFBRDMxMUVDODBGNDk3REM3N0IyMTFBMyIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDpCMDE2RjJDMzFBRDMxMUVDODBGNDk3REM3N0IyMTFBMyIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgQ1M2IChXaW5kb3dzKSI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjBBRjY0NUQzQ0QxQUVDMTE5MkY4OEI2NDc4MDRGOTIxIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjNERTc4NDVFRDRCMkU5MTE5QUE2OEIyRkU1RThEOTUwIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+rPVkrwAAAQFJREFUeNpiZqAM2ADxeiC2pMQQISC+CMT/gfgtuYZwAfEyqCF/gDiCXINygfgX1KA+cg0xB+InUEMOATEnOYaIAfFpqCEPgdiQHEPYgLgDaggIZ5DrJVsg/gY1ZB4Qs6MrYILahg8IAvEVqCFngFgZm6K5QHwLiFOBmAWHQQVQQ34CcRou254ipYd6IObA4prPUDVb8DnbFIgvIwXiBGiCg4F2qPgnIHYiFJDyQLwfybCZSIEJc/FuYmNFFIg3IRlWDcS+QPwVGja+pESxMBBvgBoEMuA5EP8D4rt4IgInEAHijUguA+H55CY+UExtRTIom5Lyhgdq2B0gViCkGCDAAMT4RD4+ZXKNAAAAAElFTkSuQmCC"
                    alt="cheak" /> ​Each NFT is unique and generated from over 40 traits. I Died But I Survived are stored as
                    tokens Ethereum Mainnet on the ETH Blockchain. Minting a NFT costs 1 ETH.</li>

                </ul>
              </div>
            </div>
          </div>
        </div>
      </section> */}
        {/* <section  className="welcome-part py-6 jumptarget" id="welcome">
        <div className="container">
          <div className="row gap50">
            <div className="col-lg-4 mb-lg-0 mb-3 welcome aos-init aos-animate" data-aos="fade-up"><img src="./1.gif" alt=""
              className="img-fluid" /></div>
            <div className="col-lg-8 aos-init aos-animate" data-aos="fade-up"><span className="text-warning welcome-text">Welcome
              To </span>
              <h2 className="main-title white">I Died But I Survived</h2>
              <p className="welcome-para">The name speaks for itself on June 15th, 2022 I took 80 pills to end my life as I thought at that moment I had nothing to live for, but immediately after doing it I realized it was wrong and headed to my Jeep to get to the emergency room.<br /><br />I was at a hotel I forgot my phone inside and passed out in my Jeep for over (7) Seven hours. Had it not been for 2 determined loved ones calling that phone looking for me. I would not be here, I spent one week at ICU and then one week in Psych. So I was dead but I Survived.<br /><br /> I welcome everyone so together we can help those suffering from depression, bullying, and other Mental health issues who may think that they are alone like I thought, but you are not. There are resources out there to help you to help us reach out. Some links on this site tell you where you can get help, and speak to someone, reach out to someone, get help don’t do like I did. Mental Health Matters. Together from this mint and this project we can help a lot of persons who may be I’m a dark place as I was now and continue in the future.<br /><br /> Remember again. Mental Health Matters.

              </p>

            </div>
          </div>
        </div>
      </section> */}

        {/* <section id="milestones" className="milestones-part py-6 jumptarget">
        <div className="container">
          <h2 className="title-roadmap">ROADMAP</h2>
          <p className="rarity-para"></p>
          <div className="timeline time-body">
            <div data-aos="fade-down" data-aos-delay="200" className="time-container right two aos-init">
              <div className="time-content">
              <h2 className="milestones-title">Launch</h2>
                <p>
                 Twitter, Instagram, and Discord.
                </p>
              </div>
            </div>
            <div data-aos="fade-down" data-aos-delay="300" className="time-container left two aos-init">
              <div className="time-content">

                <p>
                After Sold out 40% percent goes To the American Suicide Prevention Foundation and other similar Foundations and Charities and other similar organizations that help persons with mental health issues that may want to end their life and we are using The Giving Block to do the transfers.
                </p>
              </div>
            </div>
            <div data-aos="fade-down" data-aos-delay="300" className="time-container right four aos-init">
              <div className="time-content">
                <p>
                50000USD in ETH goes to a volunteer ambulance organization to either refurbish or towards buying an ambulance if it’s enough as without them I’m not here. 
                </p>

              </div>
            </div>

            <div data-aos="fade-down" data-aos-delay="300" className="time-container left two aos-init">
              <div className="time-content">
                <p>
                10% goes back to 2 buyers to be shared equally in a one-off raffle.
                Plus a merch store where 50 percent of proceeds goes towards helping those who may want to end their life like I did and it would be used for the continued support of those with mental health problems as I did.
                </p>
              </div>
            </div>


            <div data-aos="fade-down" data-aos-delay="300" className="time-container right four aos-init">
              <div className="time-content">
                <p>
                After this, we continue to help the Mental Health Community with projects chosen by the Community.
                If I can at least save one life with this it helps.
                </p>

              </div>
            </div>


          </div>
        </div>

      </section>
      <section className="slider-part bg-dark py-3">
        <div className="marquee-wrapper">
          <h2>800 I Died But I Survived with 40+ traits</h2>
          <div className="container">
            <div className="marquee-block">

              <div className="marquee-inner to-left"><span>
                <div className="marquee-item"><img src="assets/10.png" alt="img1" /></div>
                <div className="marquee-item"><img src="assets/11.png" alt="img2" /></div>
                <div className="marquee-item"><img src="assets/12.png" alt="img3" /></div>
                <div className="marquee-item"><img src="assets/13.png" alt="img4" /></div>
                <div className="marquee-item"><img src="assets/14.png" alt="img5" /></div>
                <div className="marquee-item"><img src="assets/15.png" alt="img6" /></div>
                <div className="marquee-item"><img src="assets/16.png" alt="img7" /></div>
                <div className="marquee-item"><img src="assets/17.png" alt="img8" /></div>
                <div className="marquee-item"><img src="assets/18.png" alt="img9"/></div>
                <div className="marquee-item"><img src="assets/19.png" alt="img10" /></div>
                <div className="marquee-item"><img src="assets/20.png" alt="img11" /></div>




              </span><span>
                  <div className="marquee-item"><img src="assets/10.png" alt="img1" /></div>
                  <div className="marquee-item"><img src="assets/11.png" alt="img2" /></div>
                  <div className="marquee-item"><img src="assets/12.png" alt="img3" /></div>
                  <div className="marquee-item"><img src="assets/13.png" alt="img4" /></div>
                  <div className="marquee-item"><img src="assets/14.png" alt="img5" /></div>
                  <div className="marquee-item"><img src="assets/15.png" alt="img6" /></div>
                  <div className="marquee-item"><img src="assets/16.png" alt="img7" /></div>
                  <div className="marquee-item"><img src="assets/17.png" alt="img8" /></div>
                  <div className="marquee-item"><img src="assets/18.png" alt="img9" /></div>
                  <div className="marquee-item"><img src="assets/19.png" alt="img10" /></div>
                  <div className="marquee-item"><img src="assets/20.png" alt="img11" /></div>

                </span></div>
            </div>
          </div>
        </div>
      </section>
      <section id="aboutme" className="faq-part py-6 bg-dark jumptarget">
        <div className="container">
          <h2 data-aos="fade" className="main-title center white aos-init"><strong>About me</strong></h2>
          <p>I am 52 years old on June 15th, 2022 I took 40 white Tylenol pills and 40 blue Tylenol pills all at once in an effort to end my life. At that moment with all that was happening in my life, I thought it was my only option which I later realizes was not but at that moment I was so overwhelmed I thought it was. Had it not been for my aunt a good friend and those ambulance drivers I would not be here today. I sent 7 days in the ICU then another 7 days in the psych ward I came home on the 28th.<br /><br /> I am appreciative of being alive and I am doing an NFT project called I died but I survived. My, I have produced 800 NFTs selling for 1ETH each 40% percent goes to organizations that help a person with mental health issues that may want to end their life like I tried to.
          <br /><br />
          Feeling alone, frightened, and afraid to speak to anyone is not well mental health matters, and you are not alone. I have included links to websites where you can find resources available if you need help.
             <br /><br />
             If I can at least save one life with this it helps,
             <br /><br />
            Thank you.
          </p>

        </div>
      </section>
      <section id="team" className="team-part py-6 bg-warning jumptarget">
        <div className="container">
          <h2 className="main-title center whitedot"><strong>Team</strong></h2>
          <p className="rarity-para"></p>
          <div className="row centerjust">

            <div className="col-lg-4 text-center mb-lg-0 mb-4 aos-init maxh" data-aos="fade" data-aos-duration="1500"
              data-aos-delay="600"><img className="mb-2 teamimg" src="/team.jpg" alt="TeamImage2" />
              <h4 className="font-weight-bold team-title">Collin<span className="font-weight-normal d-block"><br/>Founder,
                Survivor, Developer</span></h4>
          
            </div>

          </div>
        </div>
      </section>
      <section id="faq" className="faq-part py-6 bg-dark jumptarget">
        <div className="container">
          <h2 data-aos="fade" className="main-title center white aos-init"><strong>Frequently Asked Questions</strong></h2>
          <Accordion className="faq-item">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>What is I Died But I Survived NFT?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                  I Died But I Survived is an NFT “non-fungible token” collection. It is a digital item collection that can be bought and traded through blockchain that also unlocks benefits and perks exclusive to the holder, such as raffles, surprise drops, charity donations, and access to a community.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion className="faq-item">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography>When is the release date ?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                  To be announced.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion className="faq-item">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography>What will be the Minting Price?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                  Sale is for everyone : Each I Died But I Survived NFT can be Minted For 1 ETH.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion className="faq-item">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography>What is the ultimate goal of the project?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                  I Died But I Survived NFT’s are here to help our community and those thinking of Suicide or suffering Mental Health issues as I was when I tried to end my life. A certain percentage of our Revenues from both primary and secondary sales will be returned to you and chosen Charities. Our goal is to reach as many as possible, a person suffering from mental health issues to support them emotionally, medically, and financially.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion className="faq-item">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography>Will you be transparent with your donations?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                  For donations we have chosen the Giving Block as our means of donating to our charities and a copy of each donation receipt would be posted on Discord, Twitter and Instagram.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion className="faq-item">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography>How may I Died But I Survived NFT’s are there?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                  There will be 800 I Died But I Survived NFT’s produced. We will keep 40 for reinvestment and social causes, so 800 will be available for purchase. 
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion className="faq-item">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography>How do I Purchase I Died But I Survived NFT’s?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>You can MINT I Died But I Survived on our website by connecting your wallet.
                    Secondary sales will be available on Opensea.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion className="faq-item">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography>Which Wallet is to be used for Minting and how many per Transaction?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>Metamask wallet or similar with appropriate ETH balance. There is no limit. 
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion className="faq-item">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography>What is the MINT type?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>MINT type is a raffle and instantly revealed once the transaction is completed.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion className="faq-item">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography>Are there any Royalties?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>Yes, royalties are set to 5%. They are used for the continuous growth and
                  development of the project.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion className="faq-item">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography>Do you have more Questions for the I Died But I Survived team?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>For any further queries please raise a ticket on our discord channel # │create-ticket and we will be with you shortly.
                  </Typography>
                </AccordionDetails>
              </Accordion>

        </div>
      </section>
      <section id="howto" className="faq-part py-6 bg-dark jumptarget">
        <div className="container">
          <h2 data-aos="fade" className="main-title center white aos-init"><strong>How to Mint a I Died But I Survived?</strong></h2>
          <ol>
            <li>We recommend MetaMask  Wallet to purchase the "I Died But I Survived" as it's easy to use.</li>
            <li><a href="https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn"
              target="_blank" rel="noreferrer"></a>Download MetaMask Wallet extension to Chrome
              Browser. </li>
            <li>Go through the steps described in the link from step 1. Make sure you open your new wallet and deposit. If
              you're having any issues, get in touch with the team and we'll help!<br/>Do NOT share your password with
                strangers!<br/>Remember, our team members NEVER ask for passwords!</li>
                <li>The MINT cost for each "I Died But I Survived" is 1 ETH Make sure
                  that you have an appropriate balance in your wallet to account for transaction fees.</li>
                <li>We have the MINT button on our website. We will activate it once we release the site, you'll see a
                  'Connect Wallet' button.</li>
                <li>Once you click the Connect Wallet button, it will bring up a dropdown of all the available wallets to
                  connect. Make sure to select the wallet you're using.</li>
                <li>When your wallet is connected, you'll see the 'Mint 1 I Died But I Survived' button appear. Click the button,
                  approve the transaction in your wallet.</li>
                <li>Wait approximately 40 seconds and your "I Died But I Survived" will appear in your wallet! Reveal is instant, and
                  you will be able to see which "I Died But I Survived" you have minted on your Opensea account as soon as it's in your wallet!</li>
              </ol>
            </div>
          </section>

          <h2 className="main-title center white aos-init" ><strong>As Seen On:</strong></h2>
          <a href="https://nftcalendar.io/" target="_blank" rel="noreferrer">
              <img className="calendar" src="/icons/calendar.svg" alt="" />
            </a> */}

        <footer className="footer-part text-center">
          <div className="container">
            {/* <div className="border-bottom py-2 my-4">          
                <p>​Follow us on Twitter, Instagram and join our Discord channel to learn more about us, catch up with the
                  latest news and participate in giveaways.</p>
                <ul className="list-inline m-0 ftr-link">
                  <li className="list-inline-item"><a className="btn" href="https://discord.gg/qM32teyM8p" target="_blank"
                    rel="noreferrer"><i className="fab fa-twitter mr-2" aria-hidden="true"></i>Join Our
                    Discord</a></li>
                  <li className="list-inline-item"><a className="btn" href="https://twitter.com/diedbutsurvived/" target="_blank"
                    rel="noreferrer"><i className="fab fa-twitter mr-2" aria-hidden="true"></i>Join Our Twitter</a></li>
                  <li className="list-inline-item"><a className="btn" href="https://instagram.com/idiedbutisurvived/" target="_blank"
                    rel="noreferrer"><i className="fab fa-instagram mr-2" aria-hidden="true"></i>Join Our Instagram</a></li>

                </ul>
              </div> */}
            <p> I Died But I Survived - Copyright © 2023</p>
          </div>
        </footer>
      </div>
    </div>
  );
}

export default App;
